<template>
  <div class="formview">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="text-center">
            <p class="w-back mb-0">Welcome to Imperium!</p>
            <h3 class="formheader f-semibold text-dark">Create account</h3>
          </div>
          <form @submit.prevent="submitForm">
            <div class="my-5">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <input
                      required
                      v-model="form.first_name"
                      placeholder="First name"
                      type="text"
                      class="form-control text-dark"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <input
                      required
                      v-model="form.last_name"
                      placeholder="Last name"
                      type="text"
                      class="form-control text-dark"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <input
                      required
                      v-model="form.email"
                      placeholder="Email address"
                      type="email"
                      class="form-control text-dark"
                      @blur="
                        form.email.match(rex)
                          ? (mailErr = false)
                          : (mailErr = true)
                      "
                    />
                    <p class="error-text" v-if="mailErr">Invalid Email</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <input
                      required
                      placeholder="Phone number"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      type="number"
                      maxlength="11"
                      v-model="form.phone_number"
                      class="form-control text-dark mynum"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <div class="w-100 pasw">
                      <input
                        required
                        placeholder="Password"
                        v-model="form.password"
                        :type="[showPas ? 'text' : 'password']"
                        class="form-control text-dark"
                        @focus="errorText = false"
                        @blur="
                          form.password.length > 7
                            ? (errorText = false)
                            : (errorText = true)
                        "
                      />
                      <span v-if="form.password" @click="togglePas">{{
                        showPas ? "Hide" : "Show"
                      }}</span>
                    </div>
                    <p class="error-text" v-if="errorText">
                      Password must be at least 8 characters
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <div class="w-100 pasw">
                      <input
                        required
                        placeholder="Confirm Password"
                        :type="[showConf ? 'text' : 'password']"
                        v-model="form.confirm_password"
                        class="form-control text-dark"
                        @focus="confirmError = false"
                        @blur="
                          form.confirm_password !== form.password
                            ? (confirmError = true)
                            : (confirmError = false)
                        "
                      />
                      <span v-if="form.confirm_password" @click="toggleConf">{{
                        showConf ? "Hide" : "Show"
                      }}</span>
                    </div>
                    <p class="error-text" v-if="confirmError">
                      Password Mismatch
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <input
                      required
                      v-model="form.address.street"
                      @blur="
                        form.address.street.length < 20
                          ? (aderr = true)
                          : (aderr = false)
                      "
                      placeholder="Address"
                      class="form-control text-dark"
                    />
                    <p class="error-text" v-if="aderr">
                      Address is not detailed enough
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <select
                          required
                          v-model="form.address.state"
                          placeholder="State"
                          class="form-control form-select text-dark"
                          @change="form.address.lga = ''"
                        >
                          <option disabled selected value="">State</option>
                          <option
                            v-for="(item, index) in allStates"
                            :key="index"
                          >
                            {{ item }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <select
                          required
                          v-model="form.address.lga"
                          placeholder="LGA"
                          class="form-control text-dark"
                        >
                          <option disabled selected value="">LGA</option>
                          <option v-for="(item, index) in lgas" :key="index">
                            {{ item }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group text-center mt-5">
                <button
                  :disabled="loading"
                  class="
                    btn btn-imp-secondary
                    bg-primary
                    btn-icon
                    text-secondary
                    py-3
                    px-5
                    btn-block
                  "
                  type="submit"
                >
                  <span>Create account</span>
                  <BtnLoading v-if="loading" class="btn-loading" />
                </button>
              </div>
              <div class="text-center mt-4">
                Already have an account?
                <router-link class="text-primary" to="/login"
                  >Login</router-link
                >
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import BtnLoading from "@/components/BtnLoading.vue";
import axios from "axios";
import NaijaStates from "naija-state-local-government";
export default {
  data() {
    return {
      aderr: false,
      errorText: false,
      mailErr: false,
      confirmError: false,
      loading: false,
      showPas: false,
      showConf: false,
      allStates: NaijaStates.states(),
      rex: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
      form: {
        email: "",
        first_name: "",
        last_name: "",
        password: "",
        confirm_password: "",
        phone_number: "",
        address: {
          lga: "",
          state: "",
          street: "",
        },
      },
    };
  },
  computed: {
    lgas() {
      if (this.form.address.state) {
        var lg = NaijaStates.lgas(this.form.address.state).lgas;
        return lg;
      }
    },
  },
  components: { BtnLoading },
  mounted() {},
  methods: {
    togglePas() {
      this.showPas = !this.showPas;
    },
    toggleConf() {
      this.showConf = !this.showConf;
    },
    ...mapActions("notificationModule", ["showModal"]),
    submitForm() {
      if (this.form.password === this.form.confirm_password) {
        var validRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (this.form.email.match(validRegex)) {
          if (!this.aderr) {
            this.loading = true;
            let data = this.form;
            data = this.$helpers.encrypt(JSON.stringify(data));

            data = {
              data,
            };
            let payload = {
              data,
              path: "/customer",
            };
            this.$store
              .dispatch("authRequest", payload)
              .then((resp) => {
                let decr = JSON.parse(this.$helpers.decrypt(resp.data.data));
                resp.data = decr;
                this.loading = false;
                let token = resp.data.data.token;
                let user = {
                  user: resp.data.data.user,
                  customer: resp.data.data.customer,
                };

                token = this.CryptoJS.AES.encrypt(
                  token,
                  this.$passPhrase
                ).toString();

                user = this.CryptoJS.AES.encrypt(
                  JSON.stringify(user),
                  this.$passPhrase
                ).toString();

                // localStorage.setItem("token", token);
                // localStorage.setItem("user", user);
                // axios.defaults.headers.common[
                //   "Authorization"
                // ] = `Bearer ${token}`;
                this.showModal({
                  type: "success",
                  description: "New account created, Please check your mail",
                  display: true,
                });
                // this.$toast.success(
                //   "Please check your mail",
                //   "New account created successful!",
                //   this.$toastPosition
                // );
                // this.$toast.success(
                //   "Email",
                //   "An email notificationhas been sent to your email",
                //   this.$toastPosition
                // );
                // setTimeout(() => {
                //   window.location.href = "/categories";
                // }, 1000);
                setTimeout(() => {
                  if (this.$route.query.cart === "true") {
                    // window.location.href = "/cart";
                    this.$router.push("/login");
                  } else {
                    // window.location.href = "/categories";
                    this.$router.push("/login");
                  }
                }, 2000);
              })
              .catch((err) => {
                if (err.response) {
                  let decr = JSON.parse(
                    this.$helpers.decrypt(err.response.data.data)
                  );
                  err.response.data = decr;
                  this.$toast.info(
                    "Sign up",
                    err.response.data.message,
                    this.$toastPosition
                  );
                } else {
                  this.$toast.info(
                    "Sign up",
                    "Unable to sign up, check your internet connection",
                    this.$toastPosition
                  );
                }

                this.loading = false;
              });
          } else {
            this.$toast.info(
              "Address",
              "Address is not detailed enough",
              this.$toastPosition
            );
          }
        } else {
          this.$toast.info(
            "Email",
            "Invalid email address",
            this.$toastPosition
          );
        }
      } else {
        this.$toast.info(
          "Password",
          "Password Mismatch, check passowrd",
          this.$toastPosition
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.error-text {
  font-size: 0.8rem;
  color: #c70039;
}
.w-back {
  font-weight: bold;
  font-size: 16px;
  line-height: 52px;
  color: #255e13;
}
.formheader f-bold {
  font-size: 24px;
  color: #121113;
}
.formview {
  padding: 55px 0px;
  min-height: 90vh;
}

input.mynum::-webkit-outer-spin-button,
input.mynum::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.mynum[type="number"] {
  -moz-appearance: textfield;
}
.pasw {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  padding-right: 0.75rem;
}
.pasw .form-control {
  border: none;
  border-radius: 0;
}
.pasw span {
  font-size: 0.8rem;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
</style>
